import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import AOS from 'aos';
import TandemMap from '../components/common/TandemMap';
import Curriculum from '../components/common/Curriculum';
import LazyLoad from 'react-lazyload';
import ParticipantGallery from "../components/common/ParticipantGallery"

import { useDispatch, useSelector } from "react-redux";
import {
  EditableParagraph,
  EditableText,
  EditableImageUpload,
  EditableLink,
} from "react-easy-editables";
import { uploadFile } from "../aws/operations";

import {
  updatePageContent,
  loadPageData,
} from "../redux/actions";

import Layout from "../layouts/default.js";
import "../assets/sass/events.scss";
import EventGallery from "../components/common/EventGallery";

const FellowshipPage = (props) => {

  const dispatch = useDispatch();

  useEffect(() => {
    const { data: { pages } } = props;
    const initialPageData = {
      ...pages,
      content: JSON.parse(pages.content)
    };
    dispatch(loadPageData(initialPageData));

    AOS.init({ delay: 50, duration: 400 })
  }, [ dispatch, props ]);

  const pageData = useSelector((state) => state.page?.data);

  if (!pageData) {
    // first render, we haven't saved it to redux state yet
    return null;
  }

  const { content, archived, id } = pageData;

  const onSave = id => content => {
    dispatch(updatePageContent(id, content));
  };

  return (
    <Layout location={props.location} hideContact>
      <Helmet>
        <title>{pageData.title}</title>
        <meta description={pageData.description} />
      </Helmet>

      <section id="landing" data-aos="fade-up" data-aos-delay="500" className="pt-15 pb-8">
        <Container>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <div className="landing-body">
                <div className="landing-intro">
                  <h1 className="text-black">
                    <EditableText content={content["title-line1"]} onSave={onSave("title-line1")} />
                  </h1>
                  <h1 className="text-gradient">
                    <EditableText content={content["title-line2"]} onSave={onSave("title-line2")} />
                  </h1>

                  <div className="font-size-h4 mb-5 mt-5">
                    <EditableParagraph content={content["landing-subtitle"]}
                      onSave={onSave("landing-subtitle")} />
                  </div>

                  <div className="font-size-h4 text-black text-bold">
                    <EditableText content={content["landing-date"]}
                      onSave={onSave("landing-date")} />
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item md={6}>
              <div data-aos="fade-up" data-aos-delay="750" className="landing-image fellowship">
                <EditableImageUpload
                  content={content["landing-image"]}
                  onSave={onSave("landing-image")}
                  uploadImage={uploadFile}
                  styles={{ image: { objectFit: 'cover' }}}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <Container><div className="fancy-border" data-aos="flip-right" /></Container>

    {
      !archived && 
      <>
        <section id="fellowship-about" data-aos="fade-up" data-aos-delay="500" className="pt-15 pb-15">
          <Container>
            <Grid container spacing={6}>
              <Grid item md={4}>
                <div data-aos="fade-up">
                  <h2 className="text-black">
                    <EditableText content={content["about-title"]}
                      onSave={onSave("about-title")} />
                  </h2>
                  <EditableParagraph content={content["about-content"]}
                    onSave={onSave("about-content")} />
                </div>
              </Grid>
              <Grid item md={4}>
                <div className="benefits" data-aos="fade-up">
                  <h2 className="text-black mt-0 mb-1">
                    <EditableText content={content["benefits-title"]}
                      onSave={onSave("benefits-title")} />
                  </h2>
                  <h4 className="text-black">
                    <EditableText content={content["benefits-subtitle"]}
                      onSave={onSave("benefits-subtitle")} />
                  </h4>
                  <EditableParagraph content={content["benefits-content"]}
                    onSave={onSave("benefits-content")} />
                </div>
              </Grid>

              <Grid item md={4}>
                <div className="about-image">
                  <EditableImageUpload
                    content={content["about-image"]}
                    onSave={onSave("about-image")}
                    uploadImage={uploadFile}
                    styles={{ 
                      image: { 
                        objectFit: 'cover', 
                        height: '100%' 
                      },
                      container: {
                        height: '100%'
                      }
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>

        <Container><div className="fancy-border" data-aos="flip-right" /></Container>

        <section data-aos="fade-up" className="pt-15 pb-15" id="timeline">
          <Container>
            <h2 className="text-black">
              <EditableText content={content["timeline-title"]} 
                onSave={onSave("timeline-title")} />
            </h2>

            <EventGallery content={content["timeline-collection"]}
              onSave={onSave("timeline-collection")} />

          </Container>
        </section>
      </>
      }

      <Container><div className="fancy-border" data-aos="flip-right" /></Container>

      <section data-aos="fade-up" className="pt-15 pb-15" id="curriculum">
        <Container>
          <Grid container spacing={6} className="display-flex align-center">
            <Grid item md={8}>
              <h2 className="text-black">
                <EditableText content={content["curriculum-title"]} 
                  onSave={onSave("curriculum-title")} />
              </h2>

              <EditableParagraph content={content["curriculum-description"]} 
                onSave={onSave("curriculum-description")} />

            </Grid>
            <Grid item md={4}>
              <EditableImageUpload
                content={content["curriculum-image"]}
                onSave={onSave("curriculum-image")}
                uploadImage={uploadFile}
                styles={{ image: { objectFit: 'cover' }}}
              />
            </Grid>
          </Grid>

          <LazyLoad offset={200}>
            <Curriculum edition={id} />
          </LazyLoad>

        </Container>
      </section>

      <Container><div className="fancy-border" data-aos="flip-right" /></Container>

      <section id="tandems" data-aos="fade-up" className="pt-15 pb-15">
        <Container>
          <h2 className="text-black">
            <EditableText content={content["tandems-title"]}
              onSave={onSave("tandems-title")} />
          </h2>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <EditableParagraph content={content["tandems-description"]}
                onSave={onSave("tandems-description")} />
            </Grid>
            <Grid item md={6}>
              <div className="criteria mb-3">
                <EditableText content={content["eligibility-criteria-title"]}
                  onSave={onSave("eligibility-criteria-title")} />
              </div>
              <EditableParagraph content={content["eligibility-criteria"]}
                onSave={onSave("eligibility-criteria")} />
            </Grid>
          </Grid>

          <div className="mt-10">
            <LazyLoad offset={200}>
              <TandemMap edition={id} />
            </LazyLoad>
          </div>

        </Container>
      </section>

      <Container><div className="fancy-border" data-aos="flip-right" data-aos-offset="100" /></Container>

      <section className="mt-10 mb-15" data-aos="fade-up" id="people">
        <Container>
          <h2 className="text-black">
            <EditableText content={content["mentors-title"]} 
              onSave={onSave("mentors-title")} />
          </h2>

          <LazyLoad offset={200}>
            <ParticipantGallery 
              content={content["mentors-collection"]} 
              onSave={onSave("mentors-collection")} />
          </LazyLoad>
        </Container>
      </section>

      <Container><div className="fancy-border" data-aos="flip-right" data-aos-offset="100" /></Container>

      <section className="mt-10 mb-15" data-aos="fade-up" id="funding">
        <Container>
          <h2 className="text-black">
            <EditableText content={content["funding-title"]} 
              onSave={onSave("funding-title")} />
          </h2>

          <EditableParagraph content={content["funding-content"]}
            onSave={onSave("funding-content")} />

        </Container>
      </section>

      <section className="mt-10 bg-primary pt-10 pb-10" data-aos="fade-up" id="cta">
        <Container>
          <h2 className="">
            <EditableText content={content["past-event-title"]} 
              onSave={onSave("past-event-title")} />
          </h2>

          <EditableParagraph 
            className="" 
            content={content["past-event-content"] }
            onSave={onSave("past-event-content")} 
          />
          <div className="pt-2 pb-2">
            <EditableLink 
              classes="btn btn-dark text-white" 
              content={content["past-event-link"]}
              onSave={onSave("past-event-link")} 
            />
          </div>
        </Container>
      </section>

    </Layout>
  );
}

export default FellowshipPage;

export const query = graphql`
  query FellowshipPageQuery($slug: String!) {
    pages(slug: { eq: $slug }) {
      id
      title
      description
      author
      date
      content
      slug
      category
      archived
    }
  }
`;
